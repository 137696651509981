import React, { useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  ModalOverlay,
  Image,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { sendSubscriptionEmail } from "../../services/SubscribeEmail";
import { saveUserEmail } from "../../services/SaveUserEmail";

function SubscribeModal({ isOpen, onClose }) {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const notify = useToast();
  const [isEmailValid, setIsEmailValid] = useState(true);

  const handleClose = () => {
    onClose();
    setEmail("");
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailRegex.test(email));
  };

  const handleSubscribe = (e) => {
    e.preventDefault();

    const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const subscriptionTemplateId = process.env.REACT_APP_EMAILJS_CONTACT_TEMP;
    const publicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;

    if (!isEmailValid || !email) {
      return;
    }
    setLoading(true);

    Promise.all([
      sendSubscriptionEmail(email, publicKey, serviceId, subscriptionTemplateId),
    ]).then(
      (results) => {
        setEmail("");
        onClose();
        notify({
          title: "Subscription Successful",
          description: "Thank you for subscribing!",
          status: "success",
        });
        setLoading(false);

        saveUserEmail(email);

      },
      (error) => {
        notify({
          title: "Error",
          description: error.message || "Failed to send email.",
          status: "error",
        });
        setLoading(false);
      }
    );
  };

  const logo = "/images/1-icon.png";

  return (
    <Modal blockScrollOnMount={true} isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(5px)" />
      <ModalContent>
        <ModalHeader>
          <VStack spacing={2} alignItems="center">
            <Image src={logo} boxSize="60px" alt="Spatium Tech Logo" mb={2} />
            <Text>Join Us</Text>
          </VStack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubscribe}>
            <FormControl>
              <FormLabel>Email Address</FormLabel>
              <Input
                placeholder="Your email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  validateEmail(e.target.value);
                }}
                isInvalid={!isEmailValid}
              />
              {!isEmailValid && (
                <Text color="red.500">Please enter a valid email address.</Text>
              )}
            </FormControl>
            <ModalFooter>
              <Button
                disabled={loading}
                isLoading={loading}
                bg="#18D9D9"
                mr={3}
                type="submit"
              >
                Subscribe
              </Button>
            </ModalFooter>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default SubscribeModal;
